import {
  FC,
  MouseEvent as MouseSyntheticEvent,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import {twMerge} from 'tailwind-merge';
import {NavLink} from 'react-router-dom';
import {isEllipsis} from '../../../external/helpers';
import {IconProps} from '../../Icon/Icon';
import {Highlight} from '../../../external/components/Highlight/Highlight';
import {Portal} from '../../../external/components/Portal/Portal';
import {isMouseOver} from '../../../external/components/Portal/Portal.helpers';
import {Level, LevelContext} from '../../../external/context/LevelContext';
import {Checkbox, CheckboxSize} from '../../Checkbox/Checkbox';

export enum IconPolicy {
  ALWAYS_VISIBLE = 'ALWAYS_VISIBLE',
  SHOW_ON_HOVER = 'SHOW_ON_HOVER',
}

export enum DropdownListSize {
  SM = 'SM',
  MD = 'MD',
}
export type DropdownListOptionType<VALUE = string> = {
  label: string;
  value: VALUE;
  sublabel?: string;
  disabled?: boolean;
  href?: string;
  group?: string;
  disableClick?: boolean;
  isDefault?: boolean;
  maxLines?: 1 | 2;
  icon?: ReactElement<IconProps>;
};

type DropdownListOptionLabelProps = {
  option: DropdownListOptionType;
  isDisabled: boolean;
  isActive: boolean;
  isSelected: boolean;
  searchPhrase: string;
  maxLines: DropdownListOptionType['maxLines'];
};

const DropdownListOptionLabel: FC<PropsWithChildren<DropdownListOptionLabelProps>> = ({
  option,
  maxLines,
  searchPhrase,
  isActive,
  isSelected,
  isDisabled,
}) => {
  const renderContent = () => (
    <>
      <Highlight
        text={option.label}
        phrase={searchPhrase || ''}
        highlightClassName={twMerge(
          clsx('bg-primary-100', {
            'bg-primary-200': isActive && isSelected,
          }),
        )}
        className={clsx({
          'whitespace-pre-wrap line-clamp-2': (option.maxLines || maxLines) === 2 && !option.sublabel,
        })}
        ellipsis={(option.maxLines || maxLines) === 1 || !!option.sublabel}
      />
      {option.sublabel && (
        <div
          className={clsx('line-clamp-1 break-all', 'text-xs', {
            'text-grey-500': !isDisabled && !isActive,
            'text-primary-600': !isDisabled && isActive,
            'text-grey-300': isDisabled && !isSelected,
            'text-primary-500': isSelected,
          })}
        >
          {option.sublabel}
        </div>
      )}
    </>
  );

  return <div className="font-quicksand overflow-hidden">{renderContent()}</div>;
};

export type DropdownListOptionProps = {
  option: DropdownListOptionType;
  isOpenGroup: boolean;
  onMouseEnter: () => void;
  groupsAlwaysOpened: boolean;
  size: DropdownListSize;
  isOptionSelected: boolean;
  isOptionDisabled: boolean;
  isActiveOption: boolean;
  iconPolicy: IconPolicy;
  activeOptionClassName: string;
  multiple?: boolean;
  onClick: () => void;
  searchPhrase: string | null;
  maxLines: DropdownListOptionType['maxLines'];
  scrollBoxRef: RefObject<HTMLDivElement>;
  tooltipVersion: 1 | 2;
};

export const DropdownListOption: FC<PropsWithChildren<DropdownListOptionProps>> = ({
  iconPolicy,
  onClick,
  multiple = false,
  option,
  isActiveOption,
  onMouseEnter,
  isOpenGroup,
  groupsAlwaysOpened,
  size,
  isOptionDisabled,
  isOptionSelected,
  activeOptionClassName,
  searchPhrase,
  maxLines,
  scrollBoxRef,
  tooltipVersion = 1,
}) => {
  const level = useContext(LevelContext);
  const ref = useRef<HTMLDivElement>(null);
  const clientXYRef = useRef<{clientX: number; clientY: number}>();
  const [isOpen, _setOpen] = useState<boolean>(false);
  const setOpen = (nextIsOpen: boolean) => {
    if (tooltipVersion !== 2 || !ref.current || !isEllipsis(ref.current, true)) {
      return;
    }
    _setOpen(nextIsOpen);
  };

  useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      if (ref.current) {
        clientXYRef.current = {clientX: event.clientX, clientY: event.clientY};
        setOpen(isMouseOver([ref.current as HTMLElement], event, 1));
      }
    };
    const scrollHandler = () => {
      setOpen(false);
    };
    const scrollEndHandler = () => {
      if (ref.current && clientXYRef.current) {
        setOpen(isMouseOver([ref.current as HTMLElement], clientXYRef.current, 1));
      }
    };

    scrollBoxRef.current?.addEventListener('scrollend', scrollEndHandler);
    scrollBoxRef.current?.addEventListener('scroll', scrollHandler);
    document.addEventListener('mousemove', mouseMoveHandler);
    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      scrollBoxRef.current?.removeEventListener('scrollend', scrollEndHandler);
      scrollBoxRef.current?.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  if (option.group !== undefined && isOpenGroup === false) {
    return <></>;
  }

  const renderContent = () => (
    <div
      onMouseEnter={onMouseEnter}
      key={option.value}
      className={twMerge(
        clsx('pr-[16px] shrink-0 flex items-center relative text-black', {
          'pl-[16px]': groupsAlwaysOpened || option.group === undefined,
          'pl-[48px]': !groupsAlwaysOpened && option.group !== undefined,
          'h-[40px]': size === DropdownListSize.SM && !option.sublabel,
          'h-[52px]': size === DropdownListSize.SM && option.sublabel,
          'h-[48px]': size === DropdownListSize.MD && !option.sublabel,
          'h-[62px]': size === DropdownListSize.MD && option.sublabel,
          'bg-primary-100 text-primary-700 rounded-lg': isOptionSelected,
          [`bg-primary-50 text-primary-700 rounded-lg cursor-pointer ${activeOptionClassName || ''}`]:
            !isOptionDisabled && isActiveOption,
          'bg-primary-200': isActiveOption && isOptionSelected,
        }),
      )}
      data-testid="dropdown-child"
    >
      {!multiple && option.href && (
        <NavLink
          to={option.href}
          onClick={onClick}
          data-testid="dropdown-component-link"
          className={() =>
            clsx({
              'text-grey-500': isOptionDisabled && !isOptionSelected,
              'w-full h-full flex items-center appearance-none focus:outline-none': true,
              'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
              'before:h-[24px]': size === DropdownListSize.SM && !option.sublabel,
              'before:h-[40px]': size === DropdownListSize.SM && option.sublabel,
              'before:h-[32px]': size === DropdownListSize.MD && !option.sublabel,
              'before:h-[48px]': size === DropdownListSize.MD && option.sublabel,
            })
          }
        >
          <DropdownListOptionLabel
            option={option}
            isDisabled={isOptionDisabled}
            isActive={isActiveOption}
            isSelected={isOptionSelected}
            searchPhrase={searchPhrase || ''}
            maxLines={maxLines}
          />
        </NavLink>
      )}
      {!option.href && (
        <span
          onClick={onClick}
          className={clsx({
            'text-grey-500': isOptionDisabled && !isOptionSelected,
            'w-full h-full flex items-center appearance-none focus:outline-none': true,
            'before:absolute before:left-[8px] before:w-[calc(100%-16px)] before:rounded-lg': true,
            'before:h-[24px]': size === DropdownListSize.SM && !option.sublabel,
            'before:h-[40px]': size === DropdownListSize.SM && option.sublabel,
            'before:h-[32px]': size === DropdownListSize.MD && !option.sublabel,
            'before:h-[48px]': size === DropdownListSize.MD && option.sublabel,
            'pointer-events-none': !isOptionDisabled && option.disableClick,
          })}
        >
          {multiple && (
            <Checkbox
              size={CheckboxSize.SM}
              checked={isOptionSelected}
              value={option.value}
              onChange={(_next, event) => {
                (event as MouseSyntheticEvent<HTMLInputElement>).stopPropagation();
              }}
              className={clsx('mr-[8px] shrink-0')}
            />
          )}
          <DropdownListOptionLabel
            option={option}
            isDisabled={isOptionDisabled}
            isActive={isActiveOption}
            isSelected={isOptionSelected}
            searchPhrase={searchPhrase || ''}
            maxLines={maxLines}
          />
        </span>
      )}
      {option.icon && (
        <span
          className={clsx('flex w-[21px] grow-0 shrink-0', {
            'opacity-50': isOptionDisabled,
            invisible: iconPolicy === IconPolicy.SHOW_ON_HOVER && !isActiveOption,
            visible: iconPolicy === IconPolicy.SHOW_ON_HOVER && isActiveOption,
          })}
        >
          {option.icon}
        </span>
      )}
    </div>
  );

  return (
    <div ref={ref} title={tooltipVersion === 1 ? option.label : undefined}>
      <Portal
        isOpen={isOpen}
        anchorEl={ref}
        transformOrigin={{horizontal: 'left', vertical: 'center'}}
        anchorOrigin={{horizontal: 'left', vertical: 'center'}}
        backdrop={false}
        zIndex={level + Level.Message}
        portalClassName="pointer-events-none"
      >
        {renderContent()}
      </Portal>
      {renderContent()}
    </div>
  );
};
